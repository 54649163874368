"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.smsProviders = void 0;
const message_template_1 = require("../../../entities/message-template");
const credentials_1 = require("../credentials");
const provider_enum_1 = require("../provider.enum");
exports.smsProviders = [
    {
        id: provider_enum_1.SmsProviderIdEnum.Nexmo,
        displayName: 'Nexmo',
        channel: message_template_1.ChannelTypeEnum.SMS,
        credentials: credentials_1.nexmoConfig,
        docReference: 'https://developer.nexmo.com/api/sms?theme=dark',
        logoFileName: { light: 'nexmo.png', dark: 'nexmo.png' },
    },
    {
        id: provider_enum_1.SmsProviderIdEnum.Plivo,
        displayName: 'Plivo',
        channel: message_template_1.ChannelTypeEnum.SMS,
        credentials: credentials_1.plivoConfig,
        docReference: 'https://www.plivo.com/docs/',
        logoFileName: { light: 'plivo.png', dark: 'plivo.png' },
    },
    {
        id: provider_enum_1.SmsProviderIdEnum.Sms77,
        displayName: 'sms77',
        channel: message_template_1.ChannelTypeEnum.SMS,
        credentials: credentials_1.sms77Config,
        docReference: 'https://www.sms77.io/de/docs/gateway/http-api/',
        logoFileName: { light: 'sms77.svg', dark: 'sms77.svg' },
    },
    {
        id: provider_enum_1.SmsProviderIdEnum.SNS,
        displayName: 'SNS',
        channel: message_template_1.ChannelTypeEnum.SMS,
        credentials: credentials_1.snsConfig,
        docReference: 'https://docs.aws.amazon.com/sns/index.html',
        logoFileName: { light: 'sns.svg', dark: 'sns.svg' },
    },
    {
        id: provider_enum_1.SmsProviderIdEnum.Telnyx,
        displayName: 'Telnyx',
        channel: message_template_1.ChannelTypeEnum.SMS,
        credentials: credentials_1.telnyxConfig,
        docReference: 'https://developers.telnyx.com/',
        logoFileName: { light: 'telnyx.png', dark: 'telnyx.png' },
    },
    {
        id: provider_enum_1.SmsProviderIdEnum.Twilio,
        displayName: 'Twilio',
        channel: message_template_1.ChannelTypeEnum.SMS,
        credentials: credentials_1.twilioConfig,
        docReference: 'https://www.twilio.com/docs',
        logoFileName: { light: 'twilio.png', dark: 'twilio.png' },
    },
    {
        id: provider_enum_1.SmsProviderIdEnum.Gupshup,
        displayName: 'Gupshup',
        channel: message_template_1.ChannelTypeEnum.SMS,
        credentials: credentials_1.gupshupConfig,
        docReference: 'https://docs.gupshup.io/docs/send-single-message',
        logoFileName: { light: 'gupshup.png', dark: 'gupshup.png' },
    },
];
