"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emailProviders = void 0;
const credentials_1 = require("../credentials");
const message_template_1 = require("../../../entities/message-template");
const provider_enum_1 = require("../provider.enum");
exports.emailProviders = [
    {
        id: provider_enum_1.EmailProviderIdEnum.EmailJS,
        displayName: 'Email.js',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.mailJsConfig,
        docReference: 'https://www.emailjs.com/docs/',
        logoFileName: { light: 'emailjs.svg', dark: 'emailjs.svg' },
    },
    {
        id: provider_enum_1.EmailProviderIdEnum.Mailgun,
        displayName: 'Mailgun',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.mailgunConfig,
        docReference: 'https://documentation.mailgun.com/en/latest/',
        logoFileName: { light: 'mailgun.svg', dark: 'mailgun.svg' },
    },
    {
        id: provider_enum_1.EmailProviderIdEnum.Mailjet,
        displayName: 'Mailjet',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.mailjetConfig,
        docReference: 'https://documentation.mailjet.com/hc/en-us/categories/360003942934-Mailjet-Documentation-Center-',
        logoFileName: { light: 'mailjet.png', dark: 'mailjet.png' },
    },
    {
        id: provider_enum_1.EmailProviderIdEnum.Mandrill,
        displayName: 'Mandrill',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.mandrillConfig,
        docReference: 'https://mandrillapp.com/docs/?_ga=1.34114145.1141874178.1422518109',
        logoFileName: { light: 'mandrill.svg', dark: 'mandrill.svg' },
    },
    {
        id: provider_enum_1.EmailProviderIdEnum.Nodemailer,
        displayName: 'Nodemailer',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.nodemailerConfig,
        docReference: 'https://nodemailer.com/about/',
        logoFileName: { light: 'nodemailer.svg', dark: 'nodemailer.svg' },
    },
    {
        id: provider_enum_1.EmailProviderIdEnum.Postmark,
        displayName: 'Postmark',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.postmarkConfig,
        docReference: 'https://postmarkapp.com/developer',
        logoFileName: { light: 'postmark.png', dark: 'postmark.png' },
    },
    {
        id: provider_enum_1.EmailProviderIdEnum.SendGrid,
        displayName: 'SendGrid',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.sendgridConfig,
        docReference: 'https://docs.sendgrid.com/',
        logoFileName: { light: 'sendgrid.png', dark: 'sendgrid.png' },
    },
    {
        id: provider_enum_1.EmailProviderIdEnum.Sendinblue,
        displayName: 'Sendinblue',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.sendinblueConfig,
        docReference: 'https://www.sendinblue.com/',
        logoFileName: { light: 'sendinblue.png', dark: 'sendinblue.png' },
    },
    {
        id: provider_enum_1.EmailProviderIdEnum.SES,
        displayName: 'SES',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.sesConfig,
        docReference: 'https://docs.aws.amazon.com/ses/index.html',
        logoFileName: { light: 'ses.svg', dark: 'ses.svg' },
    },
    {
        id: provider_enum_1.EmailProviderIdEnum.NetCore,
        displayName: 'NetCore',
        channel: message_template_1.ChannelTypeEnum.EMAIL,
        credentials: credentials_1.netCoreConfig,
        docReference: 'https://netcorecloud.com/email/email-api/',
        logoFileName: { light: 'netcore.png', dark: 'netcore.png' },
    },
];
