"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InAppProviderIdEnum = exports.PushProviderIdEnum = exports.ChatProviderIdEnum = exports.SmsProviderIdEnum = exports.EmailProviderIdEnum = exports.CredentialsKeyEnum = void 0;
var CredentialsKeyEnum;
(function (CredentialsKeyEnum) {
    CredentialsKeyEnum["ApiKey"] = "apiKey";
    CredentialsKeyEnum["User"] = "user";
    CredentialsKeyEnum["SecretKey"] = "secretKey";
    CredentialsKeyEnum["Domain"] = "domain";
    CredentialsKeyEnum["Password"] = "password";
    CredentialsKeyEnum["Host"] = "host";
    CredentialsKeyEnum["Port"] = "port";
    CredentialsKeyEnum["Secure"] = "secure";
    CredentialsKeyEnum["Region"] = "region";
    CredentialsKeyEnum["AccountSid"] = "accountSid";
    CredentialsKeyEnum["MessageProfileId"] = "messageProfileId";
    CredentialsKeyEnum["Token"] = "token";
    CredentialsKeyEnum["From"] = "from";
    CredentialsKeyEnum["SenderName"] = "senderName";
    CredentialsKeyEnum["ApplicationId"] = "applicationId";
    CredentialsKeyEnum["ClientId"] = "clientId";
    CredentialsKeyEnum["ProjectName"] = "projectName";
    CredentialsKeyEnum["ServiceAccount"] = "serviceAccount";
    CredentialsKeyEnum["BaseUrl"] = "baseUrl";
})(CredentialsKeyEnum = exports.CredentialsKeyEnum || (exports.CredentialsKeyEnum = {}));
var EmailProviderIdEnum;
(function (EmailProviderIdEnum) {
    EmailProviderIdEnum["EmailJS"] = "emailjs";
    EmailProviderIdEnum["Mailgun"] = "mailgun";
    EmailProviderIdEnum["Mailjet"] = "mailjet";
    EmailProviderIdEnum["Mandrill"] = "mandrill";
    EmailProviderIdEnum["Nodemailer"] = "nodemailer";
    EmailProviderIdEnum["Postmark"] = "postmark";
    EmailProviderIdEnum["SendGrid"] = "sendgrid";
    EmailProviderIdEnum["Sendinblue"] = "sendinblue";
    EmailProviderIdEnum["SES"] = "ses";
    EmailProviderIdEnum["NetCore"] = "netcore";
})(EmailProviderIdEnum = exports.EmailProviderIdEnum || (exports.EmailProviderIdEnum = {}));
var SmsProviderIdEnum;
(function (SmsProviderIdEnum) {
    SmsProviderIdEnum["Nexmo"] = "nexmo";
    SmsProviderIdEnum["Plivo"] = "plivo";
    SmsProviderIdEnum["Sms77"] = "sms77";
    SmsProviderIdEnum["SNS"] = "sns";
    SmsProviderIdEnum["Telnyx"] = "telnyx";
    SmsProviderIdEnum["Twilio"] = "twilio";
    SmsProviderIdEnum["Gupshup"] = "gupshup";
})(SmsProviderIdEnum = exports.SmsProviderIdEnum || (exports.SmsProviderIdEnum = {}));
var ChatProviderIdEnum;
(function (ChatProviderIdEnum) {
    ChatProviderIdEnum["Slack"] = "slack";
    ChatProviderIdEnum["Discord"] = "discord";
})(ChatProviderIdEnum = exports.ChatProviderIdEnum || (exports.ChatProviderIdEnum = {}));
var PushProviderIdEnum;
(function (PushProviderIdEnum) {
    PushProviderIdEnum["FCM"] = "fcm";
})(PushProviderIdEnum = exports.PushProviderIdEnum || (exports.PushProviderIdEnum = {}));
var InAppProviderIdEnum;
(function (InAppProviderIdEnum) {
    InAppProviderIdEnum["Novu"] = "novu";
})(InAppProviderIdEnum = exports.InAppProviderIdEnum || (exports.InAppProviderIdEnum = {}));
