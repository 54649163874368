import React from 'react';
/* eslint-disable */
export function Invite(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9688 2.15625C17.0312 1.78125 16.875 1.40625 16.5625 1.1875C16.2188 0.96875 15.8125 0.96875 15.5 1.15625L1.5 9.15625C1.15625 9.34375 0.96875 9.6875 1 10.0625C1 10.4688 1.25 10.7812 1.59375 10.9375L4.8125 12.2812L13.9688 4.03125L7.1875 12.75C7.0625 12.9062 6.96875 13.125 6.96875 13.3438V16.375C6.96875 16.7188 7.28125 17 7.625 17C7.84375 17 8.0625 16.9062 8.1875 16.7188L9.78125 14.3438L13.5938 15.9375C13.7188 16 13.8438 16 13.9688 16C14.1562 16 14.3125 15.9688 14.4688 15.875C14.75 15.75 14.9375 15.4688 14.9688 15.1562L16.9688 2.15625Z"
        fill="white"
      />
    </svg>
  );
}
