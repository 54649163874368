"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateVariableTypeEnum = exports.ChannelCTATypeEnum = exports.StepTypeEnum = exports.ChannelTypeEnum = void 0;
var ChannelTypeEnum;
(function (ChannelTypeEnum) {
    ChannelTypeEnum["IN_APP"] = "in_app";
    ChannelTypeEnum["EMAIL"] = "email";
    ChannelTypeEnum["SMS"] = "sms";
    ChannelTypeEnum["CHAT"] = "chat";
    ChannelTypeEnum["PUSH"] = "push";
})(ChannelTypeEnum = exports.ChannelTypeEnum || (exports.ChannelTypeEnum = {}));
var StepTypeEnum;
(function (StepTypeEnum) {
    StepTypeEnum["IN_APP"] = "in_app";
    StepTypeEnum["EMAIL"] = "email";
    StepTypeEnum["SMS"] = "sms";
    StepTypeEnum["CHAT"] = "chat";
    StepTypeEnum["PUSH"] = "push";
    StepTypeEnum["DIGEST"] = "digest";
    StepTypeEnum["TRIGGER"] = "trigger";
    StepTypeEnum["DELAY"] = "delay";
})(StepTypeEnum = exports.StepTypeEnum || (exports.StepTypeEnum = {}));
var ChannelCTATypeEnum;
(function (ChannelCTATypeEnum) {
    ChannelCTATypeEnum["REDIRECT"] = "redirect";
})(ChannelCTATypeEnum = exports.ChannelCTATypeEnum || (exports.ChannelCTATypeEnum = {}));
var TemplateVariableTypeEnum;
(function (TemplateVariableTypeEnum) {
    TemplateVariableTypeEnum["STRING"] = "String";
    TemplateVariableTypeEnum["ARRAY"] = "Array";
    TemplateVariableTypeEnum["BOOLEAN"] = "Boolean";
})(TemplateVariableTypeEnum = exports.TemplateVariableTypeEnum || (exports.TemplateVariableTypeEnum = {}));
