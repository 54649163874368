"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeEntityTypeEnum = void 0;
var ChangeEntityTypeEnum;
(function (ChangeEntityTypeEnum) {
    ChangeEntityTypeEnum["NOTIFICATION_TEMPLATE"] = "NotificationTemplate";
    ChangeEntityTypeEnum["MESSAGE_TEMPLATE"] = "MessageTemplate";
    ChangeEntityTypeEnum["NOTIFICATION_GROUP"] = "NotificationGroup";
    ChangeEntityTypeEnum["FEED"] = "FEED";
})(ChangeEntityTypeEnum = exports.ChangeEntityTypeEnum || (exports.ChangeEntityTypeEnum = {}));
